<template>
  <component :is="commonComponent" id="terms" title="Terms of services">
    <section id="terms-content" class="pc_padding">
      <div class="common-content-row">
        Welcome to MoviePulse.one. By accessing or using our website, you agree to comply with
        and be bound by the following terms and conditions. Please review them carefully.
      </div>

      <div class="common-content-head">
        Acceptance of Terms
      </div>

      <div class="common-content-row">
        By using MoviePulse.one, you agree to be bound by these terms of service and our
        privacy policy. If you do not agree with any part of these terms, you must not use our
        website.
      </div>

      <div class="common-content-head">
        Use of the Website
      </div>

      <div class="common-content-row">
        You agree to use MoviePulse.one for lawful purposes only. You must not use our site in
        any way that breaches any applicable local, national, or international law or regulation.
      </div>

      <div class="common-content-head">
        Intellectual Property
      </div>

      <div class="common-content-row">
        All content on MoviePulse.one, including text, graphics, logos, and images, is the
        property of MoviePulse.one or its content suppliers and is protected by international
        copyright laws. You may not reproduce, distribute, or create derivative works from any
        content on our site without our express written permission.
      </div>

      <div class="common-content-head">
        User Content
      </div>

      <div class="common-content-row">
        You may be able to post comments and other content on MoviePulse.one. By posting
        content, you grant us a non-exclusive, royalty-free, perpetual, and worldwide license to
        use, reproduce, modify, and display such content.
      </div>

      <div class="common-content-head">
        Limitation of Liability
      </div>

      <div class="common-content-row">
        MoviePulse.one is provided on an &quot;as is&quot; and &quot;as available&quot; basis.
        We make no warranties, expressed or implied, regarding the site&#39;s operation or the
        information, content, or materials included on the site. To the full extent permissible by
        law, we disclaim all warranties, express or implied, including but not limited to implied
        warranties of merchantability and fitness for a particular purpose.
      </div>

      <div class="common-content-head">
        Changes to Terms
      </div>

      <div class="common-content-row">
        We reserve the right to modify these terms of service at any time. Your continued use of
        MoviePulse.one after any changes indicates your acceptance of the new terms.
      </div>

      <div class="common-content-head">
        Governing Law
      </div>

      <div class="common-content-row">
        These terms of service are governed by and construed in accordance with the laws of [Your
        Country/State], and you irrevocably submit to the exclusive jurisdiction of the courts in
        that location.
      </div>
    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
// 按需动态引入 PC 和 Mobile 版本的 CommonPage
const PcCommon = () => import('@/pages/components/pc_common.vue');
const MobileCommon = () => import('@/pages/components/m_common.vue');

import '@/css/common.scss';

export default {
  name: 'Terms',
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: 'Terms of services | Daily Classic Movie Recommendations - MoviePulse.one',
      meta: [
        {
          name: 'description',
          content: 'Discover a classic movie every day at MoviePulse.one. We provide detailed synopses, reviews, curated lists, and exclusive interviews to help you relive the golden age of cinema.',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
    ])
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
};
</script>